
<template>
  <div>
    <page-header :title="$route.params.id ? 'Editar curso' : 'Novo curso'"></page-header>
    <v-card style="border-radius:18px" max-width="1200px" flat outlined class="ma-auto px-5 pb-5 pt-2">
      <card-loading-transparent v-if="$route.params.id && loading"></card-loading-transparent>
      <v-card-text v-if="!$route.params.id || !loading">
        <v-form ref="newCourseForm" :readonly="$route.params.id && read_only">
          <span class="text-h6">Dados do Curso</span>
          <template v-if="new_course.id && ['active', 'selecting', 'done_selection', 'in_progress'].includes(new_course.status)">
            <v-btn v-if="read_only" outlined small style="float: right" color="normal" @click="editCancel()" >
              <v-icon small left>mdi-pencil</v-icon>
              Habilitar edição
            </v-btn>
            <v-btn v-if="!read_only" small style="float: right" color='error' @click="editCancel()" >
              <v-icon small left>mdi-pencil</v-icon>
              Cancelar
            </v-btn>
          </template>
          <v-text-field class="mt-4" v-model="new_course.name" prepend-icon="mdi-book" label="Nome" :rules="required" ></v-text-field>
          <v-switch v-model="new_course.is_international_course" label="Curso internacional" ></v-switch>
          <v-select 
            v-if="!new_course.is_international_course" 
            :items="all_coordinators" 
            v-model="new_course.coordinator_id" 
            prepend-icon="mdi-account-tie" 
            label="Coordenador do curso" 
            item-text="name" 
            item-value="id" 
            :rules="required" 
          ></v-select>
          <div v-if="new_course.is_international_course">
            <v-text-field v-model="new_course.coordinator_name" prepend-icon="mdi-account-tie" label="Nome do coordenador" :rules="required" ></v-text-field>
            <v-text-field v-model="new_course.coordinator_email" prepend-icon="mdi-at" label="Email do coordenador" :rules="emailRules" ></v-text-field>
            <v-text-field v-model="new_course.coordinator_phone" prepend-icon="mdi-phone" label="Telefone do coordenador" :rules="required" ></v-text-field>
          </div>
          <v-text-field v-model="new_course.school" prepend-icon="mdi-office-building-outline" label="Instituição de ensino" :rules="required" ></v-text-field>
          <v-textarea v-model="new_course.place" prepend-icon="mdi-google-maps" rows="3" label="Local" :rules="required" ></v-textarea>
          <v-divider class="ma-5"></v-divider>
          <span class="text-h6">Detalhes das aulas</span>
          <v-row class="mt-1">
            <v-col cols="12" sm="4">
              <date-picker 
                :read_only="$route.params.id && read_only" 
                :date="start_date_picker" 
                label="Início das aulas" 
                @datePicked="handlePickedStartDate" 
                :max="new_course.end_date"
              ></date-picker>
            </v-col>
            <v-col cols="12" sm="4">
              <date-picker 
                :read_only="$route.params.id && read_only"
                :date="end_date_picker" 
                label="Término das aulas" 
                @datePicked="handlePickedEndDate" 
                :min="new_course.start_date"
              ></date-picker>
            </v-col>
            <v-col cols="12" sm="4">
              <date-picker 
                :read_only="$route.params.id && read_only" 
                :date="register_date_picker" 
                label="Fim das inscrições"
                @datePicked="handlePickedRegisterEndDate" 
                :max="new_course.start_date"
              ></date-picker>
            </v-col>
          </v-row>
          <div>
            <span class="subtitle-1">
              Tipo de curso 
            </span>
            <v-radio-group v-model="new_course.kind" row mandatory>
              <v-radio value="distance" label="À distância" ></v-radio>
              <v-radio value="in_person" label="Presencial" ></v-radio>
            </v-radio-group>
          </div>
          <v-text-field v-model="new_course.classes_load" prepend-icon="mdi-clock-outline" label="Carga horária" :rules="required" ></v-text-field>
          <v-textarea 
            v-model="new_course.classes_load_info" 
            rows="2" 
            prepend-icon="mdi-information" 
            label="Forma de avaliação" 
            :rules="required"
          ></v-textarea>
          <v-divider class="ma-5"></v-divider>
          <span class="text-h6">Detalhes do curso</span>
          <v-row class="mt-4">
            <v-col cols="12" md="6">
              <v-text-field 
                v-model="new_course.max_grade" 
                prepend-icon="mdi-file-certificate-outline" 
                label="Nota máxima para avaliação dos alunos" 
                :rules="required" 
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field 
                v-model="new_course.avarage_grade" 
                prepend-icon="mdi-book-minus-outline" 
                label="Nota mínima para aprovação dos alunos" 
                :rules="required" 
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-textarea v-model="new_course.classes_info" prepend-icon="mdi-book-alert" rows="3" label="Informações adicionais" ></v-textarea>
          </v-row>
          <div>
            <v-row class="mt-5">
              <span class="text-h6 ml-3">Cadeiras</span>
              <v-spacer></v-spacer>
              <span class="text-h6 mr-5">Total de cadeiras: {{ total_chairs }}</span>
            </v-row>
            <v-row class="mb-3">
              <v-col cols="12" sm="6" md="4" lg="2" class="d-flex pb-0">
                <div class="d-flex justify-center mt-3 mr-2">
                  <country-flag country="br" size="normal" ></country-flag>
                </div>
                <v-text-field v-model="new_course.chairs.brazilian" type="number" label="Brasileiros" :rules="requiredMin" ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="2" class="d-flex pb-0">
                <div class="d-flex justify-center mt-3 mr-2">
                  <country-flag country="ar" size="normal" ></country-flag>
                </div>
                <v-text-field v-model="new_course.chairs.argentine" type="number" label="Argentinos" :rules="requiredMin" ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="2" class="d-flex pb-0">
                <div class="d-flex justify-center mt-3 mr-2">
                  <country-flag country="col" size="normal" ></country-flag>
                </div>
                <v-text-field v-model="new_course.chairs.colombian" type="number" label="Colombianos" :rules="requiredMin" ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="2" class="d-flex">
                <div class="d-flex justify-center mt-3 mr-2">
                  <country-flag country="uy" size="normal" ></country-flag>
                </div>
                <v-text-field v-model="new_course.chairs.uruguayan" type="number" label="Uruguaios" :rules="requiredMin" ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="2" class="d-flex">
                <div class="d-flex justify-center mt-3 mr-2">
                  <country-flag country="py" size="normal" ></country-flag>
                </div>
                <v-text-field v-model="new_course.chairs.paraguayan" type="number" label="Paraguaios" :rules="requiredMin" ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="2" class="d-flex">
                <div class="d-flex justify-center mb-1 mr-2">
                  <v-icon size="28">mdi-diving-scuba-flag</v-icon>
                </div>
                <v-text-field v-model="new_course.chairs.other" type="number" label="Outros" :rules="requiredMin" ></v-text-field>
              </v-col>
            </v-row>
          </div>
          <v-textarea v-model="new_course.requirements" prepend-icon="mdi-information" label="Requisitos" :rules="required" ></v-textarea>

          <span class="text-h6">Programa</span>
          <v-row class="mt-1">
            <v-col v-if="new_course.program_topics && new_course.program_topics.length > 0">
              <div v-for="(topic, idx) of new_course.program_topics" :key="idx" class="d-flex">
                <v-text-field :rules="required" :label="`Tópico ${idx + 1}`" v-model="new_course.program_topics[idx]"></v-text-field>
                <div class="mt-6 ml-5">
                  <v-btn :disabled="$route.params.id && read_only" @click="removeProgramTopic(idx)" x-small color="red" dark fab elevation="1">
                    <v-icon dark>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
          <div class="d-flex justify-center">
            <v-btn :disabled="$route.params.id && read_only"  color="primary" rounded @click="addProgramTopic()" >+ tópico</v-btn>
          </div>
          <input-local-file-with-preview
            section_title="Diretrizes para inscrições"
            accepted_file_options="pdf"
            :file_name="new_course.info_file_name"
            :file_url="new_course.info_file_url"
            :required_field="true"
            :disabled="$route.params.id ? ($route.params.id && read_only) : !read_only"
            @fileToUpload="handleFileToUpload"
          />
          <v-divider class="ma-5"></v-divider>
          <span class="text-h6">Cor de exibição</span>
          <div class="d-flex justify-center mb-1 mr-2">
            <v-color-picker 
              v-model="new_course.color" 
              dot-size="25" 
              hide-inputs 
              hide-mode-switch 
              show-swatches 
              :swatches="swatches" 
              swatches-max-height="200" 
            ></v-color-picker>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn :disabled="$route.params.id && read_only" color="success" @click="submitCourse" >
          <v-icon left>mdi-content-save</v-icon>
          {{$route.params.id ? 'Atualizar' : 'Salvar'}}
        </v-btn>
        <v-btn v-if="!read_only" color="error" @click="editCancel()" >
          <v-icon left color="white">mdi-pencil</v-icon>
          {{read_only ? 'Editar' : 'Cancelar'}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import validations from '@/mixins/validations';
import Api from '@/api/index';

export default {
  name: 'NewCourse',
  mixins: [
    validations
  ],
  data() {
    return {
      swatches: [
        ['#FF0000', '#AA0000', '#550000'],
        ['#FFFF00', '#AAAA00', '#555500'],
        ['#00FF00', '#00AA00', '#005500'],
        ['#00FFFF', '#00AAAA', '#005555'],
        ['#0000FF', '#0000AA', '#000055'],
      ],
      new_course: {
        color: '',
        name: '',
        coordinator_id: null,
        coordinator_name: '',
        coordinator_email: '',
        coordinator_phone: '',
        school: '',
        place: '',
        start_date: null,
        end_date: null,
        register_end_date: null,
        kind: '',
        classes_load: '',
        classes_load_info: '',
        classes_info: '',
        chairs: {
          brazilian: null,
          argentine: null,
          colombian: null,
          uruguayan: null,
          paraguayan: null,
          other: null,
        },
        program_topics: [''],
        total_chairs: 0,
        requirements: '',
        is_international_course: false,
        info_file: null,
        max_grade: 10,
      },
      kind_map: {
        'distance': 'À distância',
        'in_person': 'Presencial'
      },
      all_coordinators: [],
      read_only: true,
      loading: true,
      dialog_file: false, 
      local_file_preview: null
    }
  },
  methods: {
    handlePickedStartDate(e) {
      this.new_course.start_date = e;
    },
    handlePickedEndDate(e) {
      this.new_course.end_date = e;
    },
    handlePickedRegisterEndDate(e) {
      this.new_course.register_end_date = e;
    },
    handleFileToUpload(file){
      this.new_course.info_file = file
    },
    editCancel(){
      if(!this.read_only){
        this.getCourse()
        this.read_only=!this.read_only
      }else{
        this.read_only=!this.read_only
      }
    },
    addProgramTopic(){
      this.new_course.program_topics.push('')
    },
    removeProgramTopic(idx){
      this.new_course.program_topics.splice( idx , 1)
    },
    getCourse(){
      Api.Course.show(this.$route.params.id).then(r => r.data).then(d => {
        let {json_attributes, info_file_name, info_file_url,  ...rest } = d
        let attr = {}
        if(info_file_name && info_file_url){
          let file_infos = {
            info_file_name: info_file_name,
            info_file_url: info_file_url
          }
          attr = {...file_infos}
        }
        this.new_course = {
          ...rest,
          ...attr,
          program_topics: json_attributes.program_topics || [''],
          start_date: this.$dayjs(d.start_date).format('YYYY-MM-DD'),
          chairs: json_attributes.chairs,
          end_date: this.$dayjs(d.end_date).format('YYYY-MM-DD'),
          register_end_date: this.$dayjs(d.register_end_date).format('YYYY-MM-DD'),
        }
      }).catch((err)=>{
        if(err.response && err.response.status && err.response.status == 404){
          this.addNotification({type: 'error', msg: 'Curso não encontrado ou não existe'})
          this.$router.push({name: 'CourseCalendar'})
        }
      }).finally(()=>{
        setTimeout(() => {
          this.loading = false
        }, 1000);
      })
    },
    getCoordinatorsList(){
      let filter = {
        role_name: 'coordinator'
      }
      Api.User.index(filter).then(r => {
        r.data.map((c)=>{
          this.all_coordinators = [
            ...this.all_coordinators,
            {
              name: c.name,
              email: c.email,
              phone: c.phone,
              id: c.id,
            }
          ]
        })
      });
    },
    submitCourse() {
      if (this.$refs.newCourseForm.validate()) {
        const ACTION = {
          METHOD: this.$route.params.id ? 'updateCourseWithFormData' : 'createCourseWithFormData',
          TEXT: this.$route.params.id ? 'atualizar' : 'criar novo',
        }
        this.$swal({
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          title: `Deseja ${ACTION.TEXT} curso`,
          icon: 'question',
        }).then(r=>{
          if (r.isConfirmed) {
            this.saveCourse(ACTION)
          };
        });
      };
    },
    saveCourse(ACTION){
      let course = this.prepareCourse()
      
      this[ACTION.METHOD](course).then((r) => {
        this.$router.push({name: 'CourseCalendar'})
      }).catch((err) =>{
        console.log(err);
      })
    },
    prepareCourse(){
      let course = {}
      this.new_course.total_chairs = this.total_chairs

      if(!this.new_course.info_file){
        delete this.new_course.info_file
      }
      if (!this.new_course.is_international_course){
        let coordinator = this.all_coordinators.find((obj) => {return obj.id == this.new_course.coordinator_id})
        course = {
          coordinator_name: coordinator.name,
          coordinator_email: coordinator.email,
          coordinator_phone: coordinator.phone,
          coordinator_id: coordinator.id
        }
      }else{
        this.new_course.coordinator_id = ''
      }

      course = { ...this.new_course, ...course }
      let course_form_data = new FormData()

      Object.entries(course).forEach(([key, value]) => {
        if( key != 'chairs' && key != 'program_topics' ){
          course_form_data.append(`course[${key}]`, value)
        }else if ( key == 'chairs'){
          Object.entries(value).forEach(([key, value]) => { 
             course_form_data.append(`course[chairs][${key}]`, value)
          })
        }else if ( key == 'program_topics'){
          for ( let i = 0 ; i < value.length ; i++ ){
            course_form_data.append(`course[${key}][]`, value[i] )
          }
        }
      })
      return course_form_data
    },
    ...mapActions({
      showCourse: 'Course/show',
      createCourseWithFormData: 'Course/create',
      updateCourseWithFormData: 'Course/update',
      addNotification: 'Notification/add',
    })
  },
  computed: {
    start_date_picker(){
      return this.new_course.start_date
    },
    end_date_picker(){
      return this.new_course.end_date
    },
    register_date_picker(){
      return this.new_course.register_end_date
    },
    total_chairs() {
      if (this.new_course && this.new_course.chairs){
        let chairs = this.new_course.chairs;
        let chair_sum = Object.values(chairs).reduce((acc, one)=> acc + Number(one), 0)
        return chair_sum;
      }
      return 0
    },
    file_to_show(){
      if(this.new_course.info_file_url && this.info_file_local_url == null){
        return this.new_course.info_file_url
      }else{
        return this.info_file_local_url
      }
    },
  },
    mounted () {
    this.getCoordinatorsList()
    if(this.$route.params.id){
      this.getCourse()
    }
  },
  watch: {
    'new_course.is_international_course'(nV) {
      if(nV && this.new_course.coordinator_id){
        this.new_course.coordinator_name = ''
        this.new_course.coordinator_phone = ''
        this.new_course.coordinator_email = ''
      }
    }
  },
}
</script>

<style>
.v-color-picker__alpha{
  display: none!important;
}
</style>